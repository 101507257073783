import React, {useState} from 'react'
import { Heart, ChevronLeft, Star, Ruler, ChevronDown, Settings, CreditCard, FileText, LogOut } from "lucide-react"

const furnitureItems = [
  { code: 'SF01', type: 'sofa', name: 'Modern Gray Sofa', price: 1200, image: '/assets/SF01_Lounge Lovers_Draper Velvet 3 Seat Sofa.png', thumbnail: '/assets/mini/SF01_Lounge Lovers_Draper Velvet 3 Seat Sofa.png', width: 840, height: 345 },
  { code: 'CH01', type: 'chair', name: 'Accent Armchair', price: 400, image: '/assets/CH01.png', thumbnail: '/assets/mini/CH01.png', width: 817, height: 931 },
  { code: 'TA01', type: 'coffee_table', name: 'Round Coffee Table', price: 250, image: '/assets/TA01_Lounge Lovers_Serena Round Coffee Table.png', thumbnail: '/assets/mini/TA01_Lounge Lovers_Serena Round Coffee Table.png', width: 200, height: 89 },
  { code: 'RG01', type: 'rug', name: 'Patterned Area Rug', price: 300, image: '/assets/RG01.png', thumbnail: '/assets/mini/RG01.png', width: 356, height: 511 },
  { code: 'LF01', type: 'floor_lamp', name: 'Arc Floor Lamp', price: 150, image: '/assets/LF01.png', thumbnail: '/assets/mini/LF01.png', width: 50, height: 150 },
  { code: 'AT01', type: 'artwork', name: 'Abstract Wall Art', price: 200, image: '/assets/AT01.png', thumbnail: '/assets/mini/AT01.png', width: 200, height: 133 },
  { code: 'CS01', type: 'cushion', name: 'Decorative Cushion', price: 30, image: '/assets/CS01.png', thumbnail: '/assets/mini/CS01.png', width: 111, height: 111 },
]

function LivingRoomDesign() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('presets')


  return (
    <div className="bg-[#e8f3eb] min-h-screen flex flex-col">
        {/* Header */}

      <header className="flex justify-between items-center w-full p-6">
        <div className="text-2xl font-bold">RoomDesigner</div>
        <div className="flex space-x-4">
          <button
            className={`px-4 py-2 rounded-full ${activeTab === 'presets' ? 'bg-black text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            // onClick={() => setActiveTab('presets')}
          >
            All Presets
          </button>
          <button
            className={`px-4 py-2 rounded-full ${activeTab === 'mydesigns' ? 'bg-black text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            // onClick={() => setActiveTab('mydesigns')}
          >
            My Designs
          </button>
          <button
            className={`px-4 py-2 rounded-full inline flex ${activeTab === 'favorites' ? 'bg-black text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            // onClick={() => setActiveTab('favorites')}
          >
            <Heart className={`w-6 h-6 text-gray-500 pr-2`} /> My Favorites
          </button>
        </div>
        <div className="relative">
          <button
            className="p-2 rounded-full bg-gray-100 text-white hover:bg-purple-100 transition-colors flex items-center"
            // onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <Settings stroke="black" className="h-6 w-6" />
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center" role="menuitem">
                  <Settings className="mr-3 h-4 w-4" /> Settings
                </a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center" role="menuitem">
                  <CreditCard className="mr-3 h-4 w-4" /> Membership
                </a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center" role="menuitem">
                  <FileText className="mr-3 h-4 w-4" /> Terms of Service
                </a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center" role="menuitem">
                  <LogOut className="mr-3 h-4 w-4" /> Log Out
                </a>
              </div>
            </div>
          )}
        </div>
      </header>


        <div className="p-4 flex items-center justify-between border-b px-2 md:px-8">
          <h1 className="text-2xl font-bold mb-2 inline">Modern Minimalist Living Room
            <button className=" ml-4 p-2 rounded-full hover:bg-gray-200">
              <Heart className="w-6 h-6" />
            </button>
          </h1>

          <div className="flex items-center space-x-4">

            <a href="/edit" className="cool_button px-8 py-2 text-white rounded-lg text-lg hover:bg-purple-500">
              Customize and Modify Furniture
            </a>
          </div>
        </div>

        {/* Main Content */}
        <div className="md:flex">
          {/* Left Column - Design Image */}
          <div className="md:w-2/3 p-4">
            <div className="relative">
              <img src="/assets/DESIGN OPTION 1.jpg" alt="Living Room Design" className="w-full h-full object-cover rounded-lg" />
            </div>

            {/* Room Dimensions 
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-2">Room Dimensions</h3>
              <div className="bg-gray-100 p-4 rounded-lg flex items-center justify-center">
                <Ruler className="w-6 h-6 mr-2" />
                <span className="text-lg font-medium">5.5m x 4.2m (18ft x 14ft)</span>
              </div>
            </div>*/}
          </div>

          {/* Right Column - Design Details */}
          <div className="md:w-1/3 p-4">
            <div className="bg-white p-6 rounded-lg shadow-md h-full">
              <div>
                <h1 className="text-2xl font-bold mb-2">Modern Minimalist Living Room</h1>
                <div className="flex items-center space-x-2 mb-4">
                  <Star className="w-4 h-4 text-yellow-400" />
                  <span className="text-sm font-medium">4.8 (52 reviews)</span>
                </div>

                <p className="text-sm text-gray-600 mb-6">
                  This contemporary living room design combines sleek lines with a neutral color palette, creating a space that's both sophisticated and inviting. The carefully curated furniture pieces and decor elements work together to achieve a balanced and harmonious look.
                </p>

                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-gray-100 p-3 rounded-lg">
                    <h3 className="font-medium mb-2">Style</h3>
                    <ul className="text-sm space-y-1">
                      <li>Modern</li>
                      <li>Minimalist</li>
                      <li>Scandinavian-inspired</li>
                    </ul>
                  </div>
                  <div className="bg-gray-100 p-3 rounded-lg">
                    <h3 className="font-medium mb-2">Color Scheme</h3>
                    <ul className="text-sm space-y-1">
                      <li>Neutral base</li>
                      <li>Gray and beige tones</li>
                      <li>Subtle pops of color</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Furniture List */}
        <div className="p-4 mt-4">
          <h3 className="text-2xl font-semibold mb-2">Furniture in this Living Room</h3>
<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
  {furnitureItems.map((item) => (
    <div key={item.code} className="flex flex-col items-center">
      <img src={item.thumbnail} alt={item.name} className="w-24 h-24 object-contain rounded-lg mb-2" />
      <span className="text-sm text-center">{item.name}</span>
      <span className="text-xs text-gray-500">{item.width}x{item.height} mm</span>
    </div>
  ))}
</div>


<h3 className="text-2xl font-semibold mb-2 pt-8">List to order the furniture</h3>

      <div className="overflow-x-auto mt-8 px-12">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Furniture</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Size</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {furnitureItems.map((item, index) => (
              <tr key={index} className="transition duration-300 ease-in-out hover:bg-gray-50">
                <td className="py-4 px-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">{item.name}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-center">
                  <div className="text-sm text-gray-500">{item.size}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-center">
                  <div className="text-sm font-semibold text-gray-900">{item.price}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-sm font-medium text-center">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cool_button bg-purple-300 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out inline-block"
                  >
                    Link To Order Item
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

        </div>


    </div>
  )
}

export default LivingRoomDesign