import React, { useState } from 'react';

const questions = [
  {
    question: "What kind of colors are you most drawn to?",
    options: [
      { answer: "Neutral tones like beige and gray", style: "Minimalist Zen" },
      { answer: "Warm earthy tones", style: "Rustic Warmth" },
      { answer: "Clean whites with pops of color", style: "Contemporary Chic" },
      { answer: "Bold and vibrant colors", style: "Eclectic Fusion" },
    ],
  },
  {
    question: "Which type of furniture appeals to you the most?",
    options: [
      { answer: "Sleek and functional", style: "Minimalist Zen" },
      { answer: "Vintage or distressed pieces", style: "Rustic Warmth" },
      { answer: "Modern designs with metallic accents", style: "Contemporary Chic" },
      { answer: "Mix and match of different styles", style: "Eclectic Fusion" },
    ],
  },
  {
    question: "What describes your ideal living space?",
    options: [
      { answer: "Open and clutter-free", style: "Minimalist Zen" },
      { answer: "Cozy and inviting", style: "Rustic Warmth" },
      { answer: "Trendy and fashionable", style: "Contemporary Chic" },
      { answer: "Unique and full of character", style: "Eclectic Fusion" },
    ],
  },
  {
    question: "Which pattern do you prefer?",
    options: [
      { answer: "No patterns, solid colors", style: "Minimalist Zen" },
      { answer: "Floral or nature-inspired patterns", style: "Rustic Warmth" },
      { answer: "Geometric or abstract patterns", style: "Contemporary Chic" },
      { answer: "A mix of various patterns", style: "Eclectic Fusion" },
    ],
  },
  {
    question: "What's your favorite type of artwork?",
    options: [
      { answer: "Minimalist prints or photography", style: "Minimalist Zen" },
      { answer: "Handmade crafts or folk art", style: "Rustic Warmth" },
      { answer: "Modern art or sculptures", style: "Contemporary Chic" },
      { answer: "Anything that catches my eye", style: "Eclectic Fusion" },
    ],
  },
];

const presetsData = {
  "Minimalist Zen": [
    { name: "Zen Retreat", image: "/assets/DESIGN OPTION 1.jpg" },
    { name: "Calm Haven", image: "/assets/DESIGN OPTION 2.jpg" },
    { name: "Serene Space", image: "/assets/DESIGN OPTION 3A.jpg" },
    { name: "Peaceful Corner", image: "/assets/DESIGN OPTION 3B.jpg" },
  ],
  "Rustic Warmth": [
    { name: "Country Comfort", image: "/assets/DESIGN OPTION 1.jpg" },
    { name: "Woodland Escape", image: "/assets/DESIGN OPTION 2.jpg" },
    { name: "Cozy Cabin", image: "/assets/DESIGN OPTION 3A.jpg" },
    { name: "Farmhouse Charm", image: "/assets/DESIGN OPTION 3B.jpg" },
  ],
  "Contemporary Chic": [
    { name: "Modern Elegance", image: "/assets/DESIGN OPTION 1.jpg" },
    { name: "Urban Loft", image: "/assets/DESIGN OPTION 2.jpg" },
    { name: "Sleek Space", image: "/assets/DESIGN OPTION 3A.jpg" },
    { name: "Chic Retreat", image: "/assets/DESIGN OPTION 3B.jpg" },
  ],
  "Eclectic Fusion": [
    { name: "Boho Bliss", image: "/assets/DESIGN OPTION 1.jpg" },
    { name: "Artistic Mix", image: "/assets/DESIGN OPTION 2.jpg" },
    { name: "Vintage Vibes", image: "/assets/DESIGN OPTION 3A.jpg" },
    { name: "Global Gathering", image: "/assets/DESIGN OPTION 3B.jpg" },
  ],
};

const QuizPage = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [resultStyle, setResultStyle] = useState('');

  const handleAnswer = (style) => {
    const updatedAnswers = [...answers, style];
    setAnswers(updatedAnswers);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      calculateResult(updatedAnswers);
    }
  };

  const calculateResult = (answersArray) => {
    const styleCount = {};
    answersArray.forEach((style) => {
      styleCount[style] = (styleCount[style] || 0) + 1;
    });
    let maxCount = 0;
    let mostPreferredStyle = '';
    for (const style in styleCount) {
      if (styleCount[style] > maxCount) {
        maxCount = styleCount[style];
        mostPreferredStyle = style;
      }
    }
    setResultStyle(mostPreferredStyle);
  };

  const progressPercentage = (currentQuestion / questions.length) * 100;

  return (
    <div className="min-h-screen bg-[#f9f6f3] relative">
      {/* Back to Homepage Button */}
      <div className="absolute top-4 left-4">
        <a href="/" className="text-purple-800 hover:underline">
          &larr; Back to Homepage
        </a>
      </div>
      {/* Main Content */}
      <div className="flex items-center justify-center min-h-screen">
        {!resultStyle ? (
          <div className="bg-white p-12 rounded-lg shadow-md max-w-5xl w-full text-center">
            {/* Progress Bar */}
            <div className="w-full bg-gray-200 rounded-full h-2 mb-6">
              <div
                className="bg-purple-600 h-2 rounded-full transition-all duration-500"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
            {/* Question */}
            <h2 className="text-4xl font-bold mb-24 pt-12">
              {questions[currentQuestion].question}
            </h2>
            {/* Answers */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {questions[currentQuestion].options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswer(option.style)}
                  className="w-full bg-purple-100 text-purple-800 py-12 px-6 rounded-lg hover:bg-purple-200 transition duration-200"
                >
                  {option.answer}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className="bg-white p-8 rounded-lg shadow-md max-w-7xl w-full flex flex-col md:flex-row">
            {/* Left Side */}
            <div className="md:w-1/2 md:pr-4 text-center md:text-left">
              <h2 className="text-3xl font-bold mb-6">
                Your preferred style could be: {resultStyle}!
              </h2>
              <p className="text-lg mb-6">
                We've got 4 presets to match your style to get you started.
              </p>
              {/* Display Presets */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
                {presetsData[resultStyle].map((preset, index) => (
                  <div key={index} className="bg-gray-100 p-4 rounded-lg">
                    <img
                      src={preset.image}
                      alt={preset.name}
                      className="w-full h-40 object-cover rounded-md mb-2"
                    />
                    <h3 className="text-xl font-bold">{preset.name}</h3>
                  </div>
                ))}
              </div>
              <p className="text-lg mb-6">
                And over <span className="font-bold">100</span> furniture pieces to add and play with!
              </p>
            </div>
            {/* Right Side */}
            <div className="md:w-1/2 md:pl-4 mt-8 md:mt-0 flex flex-col justify-center items-center">
              <h3 className="text-2xl font-bold mb-4">Get Access Now to All of Our Presets and Styles</h3>
              <p className="text-lg mb-6">
                Enter your email to access the styles and start designing.
              </p>
              <form className="w-full max-w-sm">
                <div className="mb-4">
                  <input
                    className="appearance-none w-full py-2 px-3 text-gray-700 border border-gray-300 rounded leading-tight focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    id="email"
                    type="email"
                    placeholder="Your email"
                  />
                </div>
                <button
                  type="submit"
                  className="cool_button text-white py-2 px-4 rounded-full hover:bg-purple-400 transition duration-200 w-full"
                >
                  Join Now
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizPage;
