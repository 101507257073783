import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

const designOptions = [
  { id: 1, name: 'Contemporary Chic', image: '/assets/LT01.png' },
  { id: 2, name: 'Rustic Warmth', image: '/assets/LT02.png' },
  { id: 3, name: 'Minimalist Zen', image: '/assets/VS05.png' },
  { id: 4, name: 'Modern Elegance', image: '/assets/VS02.png' }, // Corrected duplicate name
];

const arrow_right = () => {
  return (
    <div className="half_cool_border py-2 px-6 rounded-full relative border-2 border-black">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-move-right"
      >
        <path d="M18 8L22 12L18 16"></path>
        <path d="M2 12H22"></path>
      </svg>
    </div>
  );
};

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  const handleLogin = (e) => {
    // e.preventDefault();
    window.location.href='/dashboard'
  };

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      {/* Header */}
      <header className="absolute top-0 flex justify-between w-full p-6 max-w-7xl z-50">
        <a href="/" className="text-purple-800 hover:underline">
          &larr; Back to Homepage
        </a>
        <div className="flex space-x-4">
          <button
            className="less_cool_button_with_shadow py-2 px-6 rounded-full relative"
            onClick={() => window.location.href = '/signup'}
          >
            Not a Member Yet? Sign Up
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex flex-col items-center justify-center text-center px-4 relative z-10 w-full max-w-md">
        <h1 className="text-5xl font-bold text-gray-900 leading-tight mb-4">
          Welcome Back!
        </h1>
        <p className="text-xl text-gray-600 mb-8">
          Please log in to your account to continue.
        </p>

        {/* Login Form */}
        <form
          className="w-full bg-white p-6 rounded-lg shadow-md space-y-4"
        >
          {/* Email Field */}
          <div className="flex flex-col text-left">
            <label htmlFor="email" className="mb-1 text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
              placeholder="you@example.com"
            />
          </div>

          {/* Password Field */}
          <div className="flex flex-col text-left">
            <label htmlFor="password" className="mb-1 text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
              placeholder="••••••••"
            />
          </div>

          {/* Submit Button */}
          <button
            onClick={handleLogin}
            type="submit"
            className="w-full cool_button py-2 px-4 rounded-full mt-4"
          >
            Log In
          </button>

        </form>

<a href="/forgot-password" className="text-purple-600 hover:underline pt-4">
  Forgot Your Password?
</a>
      </main>

      {/* Design Options - Optional */}
      <div className="w-[80vw] hidden md:flex z-0">
        {designOptions.map((item, index) => (
          <motion.div
            key={item.id}
            className="absolute w-60 h-auto"
            style={{
              top: `${10 + index * 18}%`,
              left: `${index % 2 === 0 ? '5%' : '80%'}`,
              transform: 'translate(-50%, -50%)',
              opacity: 0.9,
              zIndex: 0,
            }}
            animate={{ y: [0, -15, 0] }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              delay: index * 0.5,
            }}
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-full h-full object-cover rounded-md"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default LandingPage;
