import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Check, Mail, CreditCard } from 'lucide-react';

const SignupSteps = () => {
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  useEffect(() => {
    // Simulate checking if email is confirmed
    const checkEmailConfirmation = setTimeout(() => {
      setEmailConfirmed(true);
      setPaymentCompleted(true);
    }, 500); // Simulating a 5-second delay for email confirmation

    return () => clearTimeout(checkEmailConfirmation);
  }, []);

  const handleStripePayment = () => {
    // Simulate Stripe payment process
    setPaymentCompleted(true);
  };

  const stepVariants = {
    incomplete: { opacity: 0.6 },
    complete: { opacity: 1 },
  };

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-md p-8 max-w-md w-full">
        <h1 className="text-3xl font-bold text-center mb-6">Complete Your Registration</h1>
        
        <div className="space-y-8">
          {/* Email Confirmation Step */}
          <motion.div
            className="flex items-center space-x-4"
            initial="incomplete"
            animate={emailConfirmed ? "complete" : "incomplete"}
            variants={stepVariants}
          >
            <div className={`rounded-full p-2 ${emailConfirmed ? 'bg-green-500' : 'bg-gray-300'}`}>
              {emailConfirmed ? <Check className="text-white" /> : <Mail className="text-gray-600" />}
            </div>
            <div>
              <h2 className="text-xl font-semibold">1. Confirm Your Email</h2>
              <p className="text-gray-600">
                {emailConfirmed
                  ? "Email confirmed! You're all set for this step."
                  : "Please check your inbox and click the confirmation link."}
              </p>
            </div>
          </motion.div>

          {/* Payment Step */}
          <motion.div
            className="flex items-center space-x-4"
            initial="incomplete"
            animate={paymentCompleted ? "complete" : "incomplete"}
            variants={stepVariants}
          >
            <div className={`rounded-full p-2 ${paymentCompleted ? 'bg-green-500' : 'bg-gray-300'}`}>
              {paymentCompleted ? <Check className="text-white" /> : <CreditCard className="text-gray-600" />}
            </div>
            <div>
              <h2 className="text-xl font-semibold">2. Pay Membership Fee</h2>
              <p className="text-gray-600">
                {paymentCompleted
                  ? "Payment received! Your membership is now active."
                  : "Complete your registration by paying the membership fee."}
              </p>
              {!paymentCompleted && (
                <button
                  onClick={handleStripePayment}
                  className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                  disabled={!emailConfirmed}
                >
                  Pay with Stripe
                </button>
              )}
            </div>
          </motion.div>
        </div>

        {emailConfirmed && paymentCompleted && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="mt-8 text-center"
          >
            <h3 className="text-2xl font-bold text-green-600 mb-2">All Set!</h3>
            <p className="text-gray-600 mb-4">Thank you for completing your registration.</p>
            <button
              onClick={() => window.location.href = '/welcome'}
              className="bg-purple-600 text-white py-2 px-6 rounded-full hover:bg-purple-700 transition duration-300"
            >
              Let's start
            </button>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default SignupSteps;