import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, ChevronLeft, Check, Loader } from 'lucide-react';

const questions = [
  {
    question: "If your home was a character, who would it be?",
    answers: [
      "A cozy grandma with warm hugs",
      "A sleek secret agent with gadgets",
      "A nature-loving explorer",
      "A quirky artist with bold ideas"
    ]
  },
  {
    question: "Pick a color you love:",
    answers: [
      "Bright and Colorful",
      "Soft and Pastel",
      "Dark and Moody",
      "Natural and Earthy"
    ]
  },
  {
    question: "Choose a material you prefer:",
    answers: [
      "Soft Fabrics",
      "Smooth Wood",
      "Cool Metal",
      "Natural Stone"
    ]
  },
  {
    question: "Select your ideal home vibe:",
    answers: [
      "Cozy and Warm",
      "Modern and Sleek",
      "Rustic and Natural",
      "Bright and Airy"
    ]
  }
];

const WelcomeSurvey = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAnswer = (answerIndex) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = answerIndex;
    setAnswers(newAnswers);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    // Simulate API call and processing
    setTimeout(() => {
      setIsLoading(false);
      // Redirect to the next page or show results
      window.location.href = '/dashboard';
    }, 6000);
  };

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center p-4">
      <AnimatePresence>
        {isLoading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-white bg-opacity-80 flex items-center justify-center z-50"
          >
            <div className="text-center">
              <Loader className="w-16 h-16 text-purple-600 animate-spin mb-4" />
              <p className="text-xl font-semibold text-purple-800">
                Creating your personalized experience...
              </p>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="survey"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white rounded-lg shadow-md p-8 max-w-2xl w-full"
          >
            <h1 className="text-3xl font-bold text-center mb-6">Let's Get to Know Your Style!</h1>
            
            <motion.div
              key={currentQuestion}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-xl font-semibold mb-4">{questions[currentQuestion].question}</h2>
              <div className="space-y-4">
                {questions[currentQuestion].answers.map((answer, index) => (
                  <motion.button
                    key={index}
                    className={`w-full text-left p-4 rounded-lg border-2 transition duration-300 ${
                      answers[currentQuestion] === index
                        ? 'border-purple-500 bg-purple-100'
                        : 'border-gray-300 hover:border-purple-300'
                    }`}
                    onClick={() => handleAnswer(index)}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    {answer}
                    {answers[currentQuestion] === index && (
                      <Check className="inline ml-2 text-purple-500" />
                    )}
                  </motion.button>
                ))}
              </div>
            </motion.div>

            <div className="mt-8 flex justify-between">
              <button
                onClick={handlePrevious}
                disabled={currentQuestion === 0}
                className={`flex items-center ${
                  currentQuestion === 0 ? 'text-gray-400 cursor-not-allowed' : 'text-purple-600 hover:text-purple-800'
                }`}
              >
                <ChevronLeft className="mr-1" />
                Previous
              </button>
              {currentQuestion === questions.length - 1 && answers.length === questions.length ? (
                <button
                  onClick={handleSubmit}
                  className="bg-purple-600 text-white py-2 px-6 rounded-full hover:bg-purple-700 transition duration-300"
                >
                  Submit
                </button>
              ) : (
                <button
                  onClick={() => setCurrentQuestion(currentQuestion + 1)}
                  disabled={answers[currentQuestion] === undefined}
                  className={`flex items-center ${
                    answers[currentQuestion] === undefined
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-purple-600 hover:text-purple-800'
                  }`}
                >
                  Next
                  <ChevronRight className="ml-1" />
                </button>
              )}
            </div>

            <div className="mt-6 flex justify-center">
              {questions.map((_, index) => (
                <motion.div
                  key={index}
                  className={`w-3 h-3 rounded-full mx-1 ${
                    index === currentQuestion ? 'bg-purple-500' : 'bg-gray-300'
                  }`}
                  initial={false}
                  animate={index === currentQuestion ? { scale: 1.2 } : { scale: 1 }}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default WelcomeSurvey;