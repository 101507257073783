import React, { useState, useRef, useEffect } from 'react'
import { Search } from 'lucide-react'

const furnitureItems = [
  { code: 'AT01', type: 'artwork', name: 'Floral Artwork', price: 200, image: '/assets/AT01.png', thumbnail: '/assets/mini/AT01.png', width: 200, height: 133 },
  { code: 'AT02', type: 'artwork', name: 'Abstract Artwork', price: 200, image: '/assets/AT02.png', thumbnail: '/assets/mini/AT02.png', width: 200, height: 133 },
  { code: 'AT03', type: 'artwork', name: 'Pink Circular Artwork', price: 180, image: '/assets/AT03.png', thumbnail: '/assets/mini/AT03.png', width: 200, height: 133 },
  { code: 'AT04', type: 'artwork', name: 'Geometric Pattern Artwork', price: 220, image: '/assets/AT04.png', thumbnail: '/assets/mini/AT04.png', width: 200, height: 133 },
  { code: 'AT05', type: 'artwork', name: 'Abstract Lines Artwork', price: 190, image: '/assets/AT05.png', thumbnail: '/assets/mini/AT05.png', width: 1005, height: 676 },
  { code: 'CH01', type: 'chair', name: 'Green Striped Chair', price: 400, image: '/assets/CH01.png', thumbnail: '/assets/mini/CH01.png', width: 817, height: 931 },
  { code: 'CH02', type: 'chair', name: 'Modern White Chair', price: 350, image: '/assets/CH02.png', thumbnail: '/assets/mini/CH02.png', width: 98, height: 198 },
  { code: 'CH03', type: 'chair', name: 'Leather Armchair', price: 500, image: '/assets/CH03.png', thumbnail: '/assets/mini/CH03.png', width: 98, height: 198 },
  { code: 'CH04', type: 'chair', name: 'Wooden Accent Chair', price: 380, image: '/assets/CH04.png', thumbnail: '/assets/mini/CH04.png', width: 98, height: 198 },
  { code: 'CH05', type: 'chair', name: 'Fabric Lounge Chair', price: 450, image: '/assets/CH05.png', thumbnail: '/assets/mini/CH05.png', width: 98, height: 198 },
  { code: 'CS01', type: 'cushion', name: 'Yellow Cushion', price: 30, image: '/assets/CS01.png', thumbnail: '/assets/mini/CS01.png', width: 111, height: 111 },
  { code: 'CS02', type: 'cushion', name: 'Patterned Cushion', price: 35, image: '/assets/CS02.png', thumbnail: '/assets/mini/CS02.png', width: 111, height: 111 },
  { code: 'CS03A', type: 'cushion', name: 'Blue Striped Cushion', price: 32, image: '/assets/CS03A.png', thumbnail: '/assets/mini/CS03A.png', width: 111, height: 111 },
  { code: 'CS03B', type: 'cushion', name: 'Green Striped Cushion', price: 32, image: '/assets/CS03B.png', thumbnail: '/assets/mini/CS03B.png', width: 111, height: 111 },
  { code: 'CS03C', type: 'cushion', name: 'Orange Striped Cushion', price: 32, image: '/assets/CS03C.png', thumbnail: '/assets/mini/CS03C.png', width: 111, height: 111 },
  { code: 'CS03D', type: 'cushion', name: 'Pink Striped Cushion', price: 32, image: '/assets/CS03D.png', thumbnail: '/assets/mini/CS03D.png', width: 111, height: 111 },
  { code: 'LF01', type: 'floor_lamp', name: 'Modern Floor Lamp', price: 150, image: '/assets/LF01.png', thumbnail: '/assets/mini/LF01.png', width: 50, height: 150 },
  { code: 'LT01', type: 'table_lamp', name: 'Table Lamp', price: 80, image: '/assets/LT01.png', thumbnail: '/assets/mini/LT01.png', width: 34, height: 38 },
  { code: 'LT02', type: 'table_lamp', name: 'Ceramic Table Lamp', price: 95, image: '/assets/LT02.png', thumbnail: '/assets/mini/LT02.png', width: 34, height: 38 },
  { code: 'LT03', type: 'table_lamp', name: 'Modern Table Lamp', price: 110, image: '/assets/LT03.png', thumbnail: '/assets/mini/LT03.png', width: 634, height: 944 },
  { code: 'RG01', type: 'rug', name: 'Red-Orange Area Rug', price: 300, image: '/assets/RG01.png', thumbnail: '/assets/mini/RG01.png', width: 356, height: 511 },
  { code: 'RG02', type: 'rug', name: 'Geometric Pattern Rug', price: 280, image: '/assets/RG02.png', thumbnail: '/assets/mini/RG02.png', width: 356, height: 511 },
  { code: 'RG03A', type: 'rug', name: 'Blue Patterned Rug', price: 320, image: '/assets/RG03A.png', thumbnail: '/assets/mini/RG03A.png', width: 356, height: 511 },
  { code: 'RG03B', type: 'rug', name: 'Green Patterned Rug', price: 320, image: '/assets/RG03B.png', thumbnail: '/assets/mini/RG03B.png', width: 356, height: 511 },
  { code: 'SF01', type: 'sofa', name: 'Light Gray Sofa', price: 1200, image: '/assets/SF01_Lounge Lovers_Draper Velvet 3 Seat Sofa.png', thumbnail: '/assets/mini/SF01_Lounge Lovers_Draper Velvet 3 Seat Sofa.png', width: 840, height: 345 },
  { code: 'SF02', type: 'sofa', name: 'Modern Sectional Sofa', price: 1500, image: '/assets/SF02.png', thumbnail: '/assets/mini/SF02.png', width: 846, height: 330 },
  { code: 'TA01', type: 'coffee_table', name: 'Yellow Coffee Table', price: 250, image: '/assets/TA01_Lounge Lovers_Serena Round Coffee Table.png', thumbnail: '/assets/mini/TA01_Lounge Lovers_Serena Round Coffee Table.png', width: 200, height: 89 },
  { code: 'TA03', type: 'side_table', name: 'Round Side Table', price: 180, image: '/assets/TA03.png', thumbnail: '/assets/mini/TA03.png', width: 89, height: 111 },
  { code: 'TA04', type: 'coffee_table', name: 'Rectangular Coffee Table', price: 280, image: '/assets/TA04.png', thumbnail: '/assets/mini/TA04.png', width: 200, height: 89 },
  { code: 'TA05', type: 'side_table', name: 'Modern Side Table', price: 160, image: '/assets/TA05.png', thumbnail: '/assets/mini/TA05.png', width: 359, height: 516 },
  { code: 'TA06', type: 'coffee_table', name: 'Glass Top Coffee Table', price: 300, image: '/assets/TA06.png', thumbnail: '/assets/mini/TA06.png', width: 200, height: 89 },
  { code: 'TA07', type: 'side_table', name: 'Wooden Side Table', price: 150, image: '/assets/TA07.png', thumbnail: '/assets/mini/TA07.png', width: 89, height: 111 },
  { code: 'VS01', type: 'vase', name: 'Checkered Vase', price: 50, image: '/assets/VS01.png', thumbnail: '/assets/mini/VS01.png', width: 27, height: 44 },
  { code: 'VS02', type: 'vase', name: 'Yellow Vase', price: 45, image: '/assets/VS02.png', thumbnail: '/assets/mini/VS02.png', width: 27, height: 44 },
  { code: 'VS03', type: 'vase', name: 'Modern White Vase', price: 55, image: '/assets/VS03.png', thumbnail: '/assets/mini/VS03.png', width: 27, height: 44 },
  { code: 'VS04', type: 'vase', name: 'Ceramic Blue Vase', price: 60, image: '/assets/VS04.png', thumbnail: '/assets/mini/VS04.png', width: 27, height: 44 },
  { code: 'VS05', type: 'vase', name: 'Tall Green Vase', price: 65, image: '/assets/VS05.png', thumbnail: '/assets/mini/VS05.png', width: 27, height: 44 }
]

// Define which furniture types should be single-instance
const singleInstanceTypes = ['sofa', 'rug']

const initialFurniture = [
  { ...furnitureItems.find(item => item.code === 'AT05'), id: 'artwork', x: 0.5, y: 0.25 },
  { ...furnitureItems.find(item => item.code === 'SF02'), id: 'sofa', x: 0.5, y: 0.51 },
  { ...furnitureItems.find(item => item.code === 'RG03A'), id: 'rug', x: 0.5, y: 0.73, rotation: 0, perspective: 0.3 },
  { ...furnitureItems.find(item => item.code === 'TA05'), id: 'side_table', x: 0.145, y: 0.54 },
  { ...furnitureItems.find(item => item.code === 'LT03'), id: 'table_lamp', x: 0.153, y: 0.36 },
  { ...furnitureItems.find(item => item.code === 'TA05'), id: 'side_table_2', x: 0.855, y: 0.54 },
  { ...furnitureItems.find(item => item.code === 'LT03'), id: 'table_lamp_2', x: 0.86, y: 0.36 },
  { ...furnitureItems.find(item => item.code === 'CH01'), id: 'chair_right', x: 0.85, y: 0.65 },
  { ...furnitureItems.find(item => item.code === 'CH01'), id: 'chair_left', x: 0.15, y: 0.65, mirrored: true },
]

export default function RoomDesigner() {
  const [furniture, setFurniture] = useState(initialFurniture)
  const [wallColor, setWallColor] = useState('rgb(239, 238, 232)')
  const [floorColor, setFloorColor] = useState('white')
  const [searchQuery, setSearchQuery] = useState('')
  const [activeTab, setActiveTab] = useState('furniture')
  const [selectedItem, setSelectedItem] = useState(null)
  const [hoveredItem, setHoveredItem] = useState(null)
  const [loadedImages, setLoadedImages] = useState({})
  // New state variables for dragging items on canvas

  // New state variables for dragging items in the list
  const [draggedItemIndex, setDraggedItemIndex] = useState(null)
  const canvasRef = useRef(null)
  const containerRef = useRef(null)

  // New state variables for dragging
  const [isDragging, setIsDragging] = useState(false)
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 })

  const filteredFurniture = furnitureItems.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const totalCost = furniture.reduce((sum, item) => sum + item.price, 0)

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = furnitureItems.map(item => new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve({ [item.code]: img })
        img.onerror = reject
        img.src = item.image
        img.crossOrigin = "anonymous"
      }))

      try {
        const loadedImgs = await Promise.all(imagePromises)
        setLoadedImages(Object.assign({}, ...loadedImgs))
      } catch (error) {
        console.error("Error loading images:", error)
      }
    }

    loadImages()
  }, [])

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    const dpr = window.devicePixelRatio || 1

    const resizeCanvas = () => {
      const aspectRatio = 1 // Fixed aspect ratio
      const maxHeight = window.innerHeight * 0.9 // 90vh
      const containerWidth = containerRef.current.clientWidth
      const size = Math.min(containerWidth, maxHeight)

      canvas.width = size * dpr
      canvas.height = size * dpr
      canvas.style.width = `${size}px`
      canvas.style.height = `${size}px`

      // Reset and scale context
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.scale(dpr, dpr)

      drawRoom()
    }

    resizeCanvas()
    window.addEventListener('resize', resizeCanvas)
    return () => window.removeEventListener('resize', resizeCanvas)
  }, [loadedImages])

  useEffect(() => {
    drawRoom()
  }, [furniture, wallColor, floorColor, loadedImages, hoveredItem, selectedItem])

  const getDrawDimensions = (item, canvasWidth) => {
    const aspectRatio = item.width / item.height
    let drawWidth, drawHeight

    switch (item.type) {
      case 'sofa':
        drawWidth = canvasWidth * 0.6
        drawHeight = drawWidth / aspectRatio
        break
      case 'rug':
        drawWidth = canvasWidth * 0.6
        drawHeight = drawWidth / aspectRatio
        break
      case 'artwork':
        drawWidth = canvasWidth * 0.3
        drawHeight = drawWidth / aspectRatio
        break
      case 'chair':
        drawWidth = canvasWidth * 0.25
        drawHeight = drawWidth / aspectRatio
        break
      case 'table_lamp':
        drawWidth = canvasWidth * 0.16
        drawHeight = drawWidth / aspectRatio
        break
      case 'side_table':
        drawWidth = canvasWidth * 0.1
        drawHeight = drawWidth / aspectRatio
        break
      default:
        drawWidth = (item.width / 1000) * canvasWidth
        drawHeight = drawWidth / aspectRatio
    }

    return { drawWidth, drawHeight }
  }

  const drawRoom = () => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    const { width, height } = canvas

    // Reset the context transformation matrix
    ctx.setTransform(1, 0, 0, 1, 0, 0)

    // Clear canvas
    ctx.clearRect(0, 0, width, height)

    // Draw wall
    ctx.fillStyle = wallColor
    ctx.fillRect(0, 0, width, height * 0.65)

    // Draw floor
    ctx.fillStyle = floorColor
    ctx.fillRect(0, height * 0.65, width, height * 0.35)

    // Draw furniture in the order they appear in the furniture array
    furniture.forEach(item => {
      const img = loadedImages[item.code]
      if (img) {
        const { drawWidth, drawHeight } = getDrawDimensions(item, width)

        ctx.save()

        if (item.type === 'rug') {
          // Apply transformations for rug
          ctx.translate(item.x * width, item.y * height)
          ctx.rotate((item.rotation || 0) * Math.PI / 180)
          ctx.transform(1, 0, 0, item.perspective || 0.3, 0, 0)
          ctx.drawImage(img, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight)
        } else {
          if (item.mirrored) {
            ctx.scale(-1, 1)
            ctx.translate(-item.x * width, item.y * height)
          } else {
            ctx.translate(item.x * width, item.y * height)
          }
          ctx.drawImage(img, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight)
        }

        // Highlight if hovered
        if (item === hoveredItem) {
          ctx.strokeStyle = 'rgba(0, 120, 255, 0.8)'
          ctx.lineWidth = 4
          ctx.strokeRect(-drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight)
        }

        // Highlight if selected
        if (item === selectedItem && item.type !== 'sofa') {
          ctx.strokeStyle = 'rgba(255, 0, 0, 0.8)'
          ctx.lineWidth = 4
          ctx.strokeRect(-drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight)
        }

        ctx.restore()
      }
    })
  }

  const handleCanvasMouseDown = (e) => {
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const ctx = canvas.getContext('2d')
    const dpr = window.devicePixelRatio || 1

    // Calculate mouse position considering device pixel ratio
    const mouseX = (e.clientX - rect.left) * dpr
    const mouseY = (e.clientY - rect.top) * dpr

    // Reset the context transformation matrix
    ctx.setTransform(1, 0, 0, 1, 0, 0)

    let clickedItem = null

    // Iterate through furniture items in reverse order for proper layering
    for (let i = furniture.length - 1; i >= 0; i--) {
      const item = furniture[i]
      const img = loadedImages[item.code]
      if (!img) continue

      const { drawWidth, drawHeight } = getDrawDimensions(item, canvas.width)

      ctx.beginPath()

      if (item.type === 'rug') {
        ctx.save()
        ctx.translate(item.x * canvas.width, item.y * canvas.height)
        ctx.rotate((item.rotation || 0) * Math.PI / 180)
        ctx.transform(1, 0, 0, item.perspective || 0.3, 0, 0)
        ctx.rect(-drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight)
        ctx.restore()
      } else {
        ctx.save()
        if (item.mirrored) {
          ctx.scale(-1, 1)
          ctx.translate(-item.x * canvas.width, item.y * canvas.height)
        } else {
          ctx.translate(item.x * canvas.width, item.y * canvas.height)
        }
        ctx.rect(-drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight)
        ctx.restore()
      }

      // Check if the mouse click is within the current item's path
      if (ctx.isPointInPath(mouseX, mouseY)) {
        clickedItem = item
        break // Exit loop on first match for performance
      }
    }

    if (clickedItem) {
      setSelectedItem(clickedItem)
      setIsDragging(true)

      // Calculate drag offset
      const itemX = clickedItem.x * canvas.width
      const itemY = clickedItem.y * canvas.height

      const offsetX = mouseX - itemX
      const offsetY = mouseY - itemY

      setDragOffset({ x: offsetX, y: offsetY })
    } else {
      setSelectedItem(null)
    }
  }

  const handleCanvasMouseMove = (e) => {
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const ctx = canvas.getContext('2d')
    const dpr = window.devicePixelRatio || 1

    // Calculate mouse position considering device pixel ratio
    const mouseX = (e.clientX - rect.left) * dpr
    const mouseY = (e.clientY - rect.top) * dpr

    // Reset the context transformation matrix
    ctx.setTransform(1, 0, 0, 1, 0, 0)

    if (isDragging && selectedItem) {
      // Update the position of the selected item
      let newX = (mouseX - dragOffset.x) / canvas.width
      let newY = (mouseY - dragOffset.y) / canvas.height

      // Clamp values between 0 and 1
      newX = Math.max(0, Math.min(newX, 1))
      newY = Math.max(0, Math.min(newY, 1))

      setFurniture(prevFurniture =>
        prevFurniture.map(item =>
          item.id === selectedItem.id
            ? { ...item, x: newX, y: newY }
            : item
        )
      )
    } else {
      // Handle hover detection
      let hovered = null

      // Iterate through furniture items in reverse order for proper layering
      for (let i = furniture.length - 1; i >= 0; i--) {
        const item = furniture[i]
        const img = loadedImages[item.code]
        if (!img) continue

        const { drawWidth, drawHeight } = getDrawDimensions(item, canvas.width)

        ctx.beginPath()

        if (item.type === 'rug') {
          ctx.save()
          ctx.translate(item.x * canvas.width, item.y * canvas.height)
          ctx.rotate((item.rotation || 0) * Math.PI / 180)
          ctx.transform(1, 0, 0, item.perspective || 0.3, 0, 0)
          ctx.rect(-drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight)
          ctx.restore()
        } else {
          ctx.save()
          if (item.mirrored) {
            ctx.scale(-1, 1)
            ctx.translate(-item.x * canvas.width, item.y * canvas.height)
          } else {
            ctx.translate(item.x * canvas.width, item.y * canvas.height)
          }
          ctx.rect(-drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight)
          ctx.restore()
        }

        // Check if the mouse is within the current item's path
        if (ctx.isPointInPath(mouseX, mouseY)) {
          hovered = item
          break // Exit loop on first match for performance
        }
      }

      // Update the hovered item state only if it has changed
      setHoveredItem((prevHovered) => {
        if (prevHovered === hovered) return prevHovered
        return hovered
      })
    }
  }

  const handleCanvasMouseUp = () => {
    if (isDragging) {
      setIsDragging(false)
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current

    // Attach event listeners
    canvas.addEventListener('mousedown', handleCanvasMouseDown)
    canvas.addEventListener('mousemove', handleCanvasMouseMove)
    canvas.addEventListener('mouseup', handleCanvasMouseUp)
    canvas.addEventListener('mouseleave', handleCanvasMouseUp) // Stop dragging if mouse leaves canvas

    return () => {
      // Clean up event listeners
      canvas.removeEventListener('mousedown', handleCanvasMouseDown)
      canvas.removeEventListener('mousemove', handleCanvasMouseMove)
      canvas.removeEventListener('mouseup', handleCanvasMouseUp)
      canvas.removeEventListener('mouseleave', handleCanvasMouseUp)
    }
  }, [handleCanvasMouseDown, handleCanvasMouseMove, handleCanvasMouseUp, furniture, selectedItem, isDragging])

  const addOrReplaceFurniture = (newItem) => {
    if (singleInstanceTypes.includes(newItem.type)) {
      setFurniture(prev => {
        // Check if an item of this type already exists
        const existingItem = prev.find(item => item.type === newItem.type)
        if (existingItem) {
          // Replace the existing item while preserving its id and position
          return prev.map(item =>
            item.id === existingItem.id
              ? {
                  ...newItem,
                  id: item.id,
                  x: item.x,
                  y: item.y,
                  rotation: newItem.type === 'rug' ? (newItem.rotation || item.rotation || 0) : undefined,
                  perspective: newItem.type === 'rug' ? (newItem.perspective || item.perspective || 0.3) : undefined
                }
              : item
          )
        } else {
          // If no existing item, add the new one with default position
          return [...prev, {
            ...newItem,
            id: `${newItem.type}_${Date.now()}`,
            x: 0.5,
            y: 0.5,
            rotation: newItem.type === 'rug' ? (newItem.rotation || 0) : undefined,
            perspective: newItem.type === 'rug' ? (newItem.perspective || 0.3) : undefined
          }]
        }
      })
    } else {
      // For multi-instance types, append the new item
      setFurniture(prev => [
        ...prev,
        {
          ...newItem,
          id: `${newItem.type}_${Date.now()}`,
          x: 0.5,
          y: 0.5
        }
      ])
    }
  }

  const updateRugTransform = (property, value) => {
    setFurniture(prev => prev.map(item =>
      item.type === 'rug' ? { ...item, [property]: value } : item
    ))
  }

  const removeItem = (id) => {
    setFurniture(prevFurniture => prevFurniture.filter(item => item.id !== id))
  }

 // Handler for drag start in the list
  const handleDragStart = (index) => {
    setDraggedItemIndex(index)
  }

  // Handler for drag over in the list
  const handleDragOver = (e, index) => {
    e.preventDefault()
    if (draggedItemIndex === null || draggedItemIndex === index) return

    setFurniture(prevFurniture => {
      const newFurniture = [...prevFurniture]
      const draggedItem = newFurniture.splice(draggedItemIndex, 1)[0]
      newFurniture.splice(index, 0, draggedItem)
      setDraggedItemIndex(index)
      return newFurniture
    })
  }

  // Handler for drag end in the list
  const handleDragEnd = () => {
    setDraggedItemIndex(null)
  }

  return (
    <div className="flex flex-col h-[80vh] bg-gray-100 rounded-xl">
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="w-80 bg-white p-4 overflow-y-auto rounded-xl">
          <div className="flex mb-4">
            {['furniture', 'settings'].map((tab) => (
              <button
                key={tab}
                className={`flex-1 py-2 px-4 text-center capitalize ${
                  activeTab === tab ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          {activeTab === 'furniture' && (
            <div className="space-y-4">
              <div className="relative">
                <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                <input
                  className="w-full pl-8 pr-2 py-2 border rounded"
                  placeholder="Search furniture..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                {filteredFurniture.map((item) => (
                  <div
                    key={item.code}
                    className={`flex flex-col items-center p-2 rounded cursor-pointer transition-all duration-200 ${
                      (singleInstanceTypes.includes(item.type)) && furniture.some(f => f.type === item.type && f.code === item.code)
                        ? 'opacity-50 bg-gray-100'
                        : 'hover:bg-blue-100'
                    }`}
                    onClick={() => addOrReplaceFurniture(item)}
                  >
                    <img src={item.thumbnail} alt={item.name} className="w-full h-32 object-contain mb-2" />
                    <span className="text-sm text-center">{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeTab === 'settings' && (
            <div className="space-y-4">
              <div>
                <label htmlFor="wall-color" className="block text-sm font-medium text-gray-700">Wall Color</label>
                <input
                  id="wall-color"
                  type="color"
                  value={wallColor}
                  onChange={(e) => setWallColor(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label htmlFor="floor-color" className="block text-sm font-medium text-gray-700">Floor Color</label>
                <input
                  id="floor-color"
                  type="color"
                  value={floorColor}
                  onChange={(e) => setFloorColor(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>
              {furniture.find(item => item.type === 'rug') && (
                <>
                  <div>
                    <label htmlFor="rug-rotation" className="block text-sm font-medium text-gray-700">Rug Rotation</label>
                    <input
                      id="rug-rotation"
                      type="range"
                      min="0"
                      max="360"
                      value={furniture.find(item => item.type === 'rug')?.rotation || 0}
                      onChange={(e) => updateRugTransform('rotation', parseInt(e.target.value))}
                      className="mt-1 block w-full"
                    />
                  </div>
                  <div>
                    <label htmlFor="rug-perspective" className="block text-sm font-medium text-gray-700">Rug Perspective</label>
                    <input
                      id="rug-perspective"
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={furniture.find(item => item.type === 'rug')?.perspective || 0.3}
                      onChange={(e) => updateRugTransform('perspective', parseFloat(e.target.value))}
                      className="mt-1 block w-full"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        {/* Canvas Area */}
        <div className="flex-1 p-4 flex items-center justify-center" ref={containerRef}>
          <canvas
            ref={canvasRef}
            className="w-4/5 h-4/5 rounded-lg shadow-lg"
            // Event handlers are attached via useEffect
          />
        </div>

        {/* Selected Items & Layer Control */}
        <div className="w-64 bg-white p-4 overflow-y-auto rounded-xl">
          <h2 className="text-lg font-semibold mb-4">Selected Items</h2>
          {furniture.map((item, index) => (
          <div
            key={item.id}
            className="flex items-center justify-between mb-2 p-2 border rounded cursor-move"
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
          >
              <div className="flex items-center">
                <img src={item.thumbnail} alt={item.name} className="w-8 h-8 mr-2" />
                <span className="text-sm mr-2">{item.name}</span>
                <button
                  className="text-red-500"
                  onClick={() => removeItem(item.id)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <div className="mt-4 pt-4 border-t">
            <h3 className="text-lg font-semibold">Total Cost</h3>
            <p className="text-2xl font-bold">${totalCost}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
