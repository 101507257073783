import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Heart, ChevronDown, Settings, CreditCard, FileText, LogOut } from "lucide-react"


const presetsData = {
  "Minimalist Zen": [
    { name: "Zen Retreat", image: "/assets/DESIGN OPTION 1.jpg" },
    { name: "Calm Haven", image: "/assets/DESIGN OPTION 2.jpg" },
    { name: "Serene Space", image: "/assets/DESIGN OPTION 3A.jpg" },
    { name: "Peaceful Corner", image: "/assets/DESIGN OPTION 3B.jpg" },
  ],
  "Rustic Warmth": [
    { name: "Country Comfort", image: "/assets/DESIGN OPTION 1.jpg" },
    { name: "Woodland Escape", image: "/assets/DESIGN OPTION 2.jpg" },
    { name: "Cozy Cabin", image: "/assets/DESIGN OPTION 3A.jpg" },
    { name: "Farmhouse Charm", image: "/assets/DESIGN OPTION 3B.jpg" },
  ],
  "Contemporary Chic": [
    { name: "Modern Elegance", image: "/assets/DESIGN OPTION 1.jpg" },
    { name: "Urban Loft", image: "/assets/DESIGN OPTION 2.jpg" },
    { name: "Sleek Space", image: "/assets/DESIGN OPTION 3A.jpg" },
    { name: "Chic Retreat", image: "/assets/DESIGN OPTION 3B.jpg" },
  ],
  "Eclectic Fusion": [
    { name: "Boho Bliss", image: "/assets/DESIGN OPTION 1.jpg" },
    { name: "Artistic Mix", image: "/assets/DESIGN OPTION 2.jpg" },
    { name: "Vintage Vibes", image: "/assets/DESIGN OPTION 3A.jpg" },
    { name: "Global Gathering", image: "/assets/DESIGN OPTION 3B.jpg" },
  ],
}

const myDesignsData = [
  { name: "My Cozy Nook", image: "/assets/DESIGN OPTION 4A.jpg" },
  { name: "Personal Paradise", image: "/assets/DESIGN OPTION 4B.jpg" },
  { name: "Dreamy Den", image: "/assets/DESIGN OPTION 4C.jpg" },
  { name: "Elegant Escape", image: "/assets/DESIGN OPTION 4D.jpg" },
]

function DashboardPage() {
  const [activeTab, setActiveTab] = useState('presets')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [favoritesData, setFavoritesData] = useState([])
  const navigate = useNavigate()

  const toggleFavorite = (design, event) => {
    event.stopPropagation()
    setFavoritesData(prev => 
      prev.some(fav => fav.name === design.name)
        ? prev.filter(fav => fav.name !== design.name)
        : [...prev, design]
    )
  }

  const handlePresetClick = (design) => {
    navigate('/preset/preview', { state: { design } })
  }

  const renderDesignCard = (design, isPreset = false) => (
    <div 
      key={design.name} 
      className="relative bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
      onClick={() => isPreset && handlePresetClick(design)}
    >
      <img
        src={design.image}
        alt={design.name}
        className="w-auto h-full object-cover"
      />
      <div className="p-4">
        <h3 className="text-lg font-medium text-gray-700">{design.name}</h3>
      </div>
      <button
        className="absolute top-2 right-2 p-2 bg-white bg-opacity-50 hover:bg-opacity-100 rounded-full"
        onClick={(event) => toggleFavorite(design, event)}
      >
        <Heart className={`w-6 h-6 ${favoritesData.some(fav => fav.name === design.name) ? 'text-red-500 fill-current' : 'text-gray-500'}`} />
      </button>
    </div>
  )

  return (
    <div className="bg-[#e8f3eb] min-h-screen flex flex-col">
      <header className="flex justify-between items-center w-full p-6">
        <div className="text-2xl font-bold">RoomDesigner</div>
        <div className="flex space-x-4">
          <button
            className={`px-4 py-2 rounded-full ${activeTab === 'presets' ? 'bg-black text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            onClick={() => setActiveTab('presets')}
          >
            Room Builder
          </button>
          <button
            className={`px-4 py-2 rounded-full ${activeTab === 'mydesigns' ? 'bg-black text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            onClick={() => setActiveTab('mydesigns')}
          >
            View All Furniture
          </button>
{/*          
          <button
            className={`px-4 py-2 rounded-full inline flex ${activeTab === 'favorites' ? 'bg-black text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            onClick={() => setActiveTab('favorites')}
          >
            <Heart className={`w-6 h-6 text-gray-500 pr-2`} /> My Favorites
          </button>
*/}
        </div>
        <div className="relative">
          <button
            className="p-2 rounded-full bg-gray-100 text-white hover:bg-purple-100 transition-colors flex items-center"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <Settings stroke="black" className="h-6 w-6" />
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center" role="menuitem">
                  <Settings className="mr-3 h-4 w-4" /> Settings
                </a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center" role="menuitem">
                  <CreditCard className="mr-3 h-4 w-4" /> Membership
                </a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center" role="menuitem">
                  <FileText className="mr-3 h-4 w-4" /> Terms of Service
                </a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center" role="menuitem">
                  <LogOut className="mr-3 h-4 w-4" /> Log Out
                </a>
              </div>
            </div>
          )}
        </div>
      </header>

      <main className="flex-1 p-8 pt-12 pb-12 my-8 rounded-lg text-center relative">
        <h1 className="text-4xl font-bold text-gray-900 px-8">
          Dashboard
        </h1>
        <p className="text-2xl text-purple-400 italic mt-4">
          Manage your presets and designs.
        </p>

        <div className="mt-8">
          {activeTab === 'presets' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              {Object.entries(presetsData).map(([category, designs]) => designs.map(design => renderDesignCard(design, true)))}
            </div>
          )}
          {activeTab === 'mydesigns' && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {myDesignsData.map(design => renderDesignCard(design))}
            </div>
          )}
          {activeTab === 'favorites' && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {favoritesData.map(design => renderDesignCard(design))}
            </div>
          )}
        </div>
      </main>
    </div>
  )
}

export default DashboardPage