import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Bedroom from './Bedroom'
import Homepage from './Homepage'
import Dashboard from './Dashboard'
import Quiz from './whatsmystylequiz'
import Login from './Login'

function App() {
  return (null)
}

export default App
