import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Bedroom from './Bedroom'
import Homepage from './Homepage'
import Dashboard from './Dashboard'
import Test from './Test'
import Quiz from './whatsmystylequiz'
import Login from './Login'
import Signup from './Signup'
import SignupFinish from './SignugFinish'
import Preview from './Preview'
import Welcome from './Welcome'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/join" element={<Signup />} />
        <Route path="/join-finish" element={<SignupFinish />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/edit" element={<Bedroom />} />
        <Route path="/whatsmystylequiz" element={<Quiz />} />
        <Route path="/test" element={<Test />} />
        <Route path="/preset/preview" element={<Preview />} />
      </Routes>
    </Router>
  )
}

export default App
