import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react'

import Demo from './demobedroom.js'

const designOptions = [
  { id: 1, name: 'Contemporary Chic', image: '/assets/LT01.png' },
  { id: 2, name: 'Rustic Warmth', image: '/assets/CS01.png' },
  { id: 3, name: 'Minimalist Zen', image: '/assets/CH01.png' },
  { id: 4, name: 'Minimalist Zen', image: '/assets/VS04.png' },
]

const presetsData = {
  "Minimalist Zen": [
    { name: "Zen Retreat", image: "/assets/DESIGN OPTION 1.jpg" },
    { name: "Calm Haven", image: "/assets/DESIGN OPTION 2.jpg" },
    { name: "Serene Space", image: "/assets/DESIGN OPTION 3A.jpg" },
    { name: "Peaceful Corner", image: "/assets/DESIGN OPTION 3B.jpg" },
  ]
}

const furnitureItems = [
  { name: "Modern Sectional Sofa", size: "108\" x 84\" x 34\"", price: "$1,299", link: "https://example.com/sofa" },
  { name: "Leather Recliner", size: "38\" x 40\" x 40\"", price: "$799", link: "https://example.com/recliner" },
  { name: "Glass Coffee Table", size: "48\" x 24\" x 18\"", price: "$349", link: "https://example.com/coffee-table" },
  { name: "Floor Lamp", size: "18\" x 18\" x 63\"", price: "$129", link: "https://example.com/floor-lamp" },
  { name: "Bookshelf", size: "36\" x 12\" x 72\"", price: "$249", link: "https://example.com/bookshelf" },
  { name: "Area Rug", size: "8' x 10'", price: "$399", link: "https://example.com/area-rug" },
];


const arrow_right = () => {
  return(
    <div className="half_cool_border py-2 px-6 rounded-full relative border-2 border-black">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-move-right"
      >
        <path d="M18 8L22 12L18 16"></path>
        <path d="M2 12H22"></path>
      </svg>
    </div>
  )
}

const LandingPage = () => {

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  }

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      <header className="absolute top-0 flex justify-between w-full p-6 max-w-7xl z-50">
        <div className="text-2xl font-bold text-purple-800">RoomDesigner</div>
        <nav className="flex space-x-8">
          <a href="/whatsmystylequiz" className="text-lg text-gray-800">Try Our Quizes</a>
          <a href="#" className="text-lg text-gray-800">Who We Are</a>
          <a href="#" className="text-lg text-gray-800">Contact Us</a>
          <a href="#" className="text-lg text-gray-800">Pricing</a>
        </nav>
        <div className="flex space-x-4">
          <button className="less_cool_button py-2 px-4 rounded-full relative" onClick={() => window.location.href='/login'}>
            Login
          </button>
          <button className="cool_button py-2 px-6 rounded-full relative" onClick={() => window.location.href='/join'}>
            Sign up
          </button>
        </div>
      </header>

      <main className="flex min-h-screen flex-col items-center justify-center text-center px-4 relative z-1">
        <h1 className="text-5xl font-bold text-gray-900 leading-tight z-50">
          Design your perfect living room.<br />Real furniture, real prices.
        </h1>
        <p className="text-4xl text-purple-400 italic mt-4">Perfect for renters.</p>
        <p className="text-lg text-gray-600 mt-8 max-w-xl">
          RoomDesigner is perfect for movers and renters who want every place to feel like home. Choose adaptable furniture that fits different styles that you can move with but remains uniquely yours.
        </p>

        <div className="flex space-x-4 mt-4">
          <button className="less_cool_button_with_shadow py-2 px-4 rounded-full relative" onClick={() => window.location.href='/whatsmystylequiz'}>
            Find Your Style With Our Quiz
          </button>
        </div>

        <div className="w-[80vw] hidden md:flex z-0">
        {designOptions.map((item, index) => (
          <motion.div
            key={item.id}
            className="absolute w-60 h-auto"
            style={{
              top: `${10 + index * 18}%`,
              left: `${index % 2 === 0 ? '0%' : '85%'}`,
              transform: 'translate(-50%, -50%)',
              opacity: 0.9,
              zIndex: 0,
            }}
            animate={{ y: [0, -15, 0] }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              delay: index * 0.5,
            }}
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-full h-full object-cover rounded-md"
            />
          </motion.div>
        ))}
        </div>

      </main>

{/*
<section className="cool_border bg-white px-12 py-24 my-8 rounded-lg shadow-md max-w-6xl w-full border-2 border-black relative">
  <h2 className="text-4xl font-bold text-center text-gray-900 mb-24">
    We understand the <span className="text-purple-400 italic">pain</span> of going through hundreds of stores and combining furniture, so we made it <span className="text-purple-400 italic">easy</span> and <span className="text-purple-400 italic">enojoybale</span>.
  </h2>
  <div className="flex items-center justify-center">
    <div className="flex flex-col items-center">
      <img src="/assets/DESIGN OPTION 3A.jpg" alt="Style Icon" className="w-auto h-24 mb-4" />
      <h3 className="text-center text-2xl font-bold text-gray-900 mb-2 pt-4">Choose a room style you like</h3>
      <p className="text-center max-w-xs pt-12">
        Explore a variety of styles to find the perfect look for your space.
      </p>
    </div>
    {arrow_right()}
    <div className="flex flex-col items-center">
      <img src="/assets/LT01.png" alt="Furniture Icon" className="w-auto h-24 mb-4" />
      <h3 className="text-center text-2xl font-bold text-gray-900 mb-2 pt-4">Adjust and Pick Furniture</h3>
      <p className="text-center max-w-xs pt-12">
        Select real furniture that matches your chosen style and fits your room.
      </p>
    </div>
    {arrow_right()}
    <div className="flex flex-col items-center px-4">
      <img src="/assets/list.png" alt="Shopping Icon" className="w-auto h-24 mb-4" />
      <h3 className="text-center text-2xl font-bold text-gray-900 mb-2 pt-4">Genereate a List where to buy every item</h3>
      <p className="text-center max-w-xs pt-12">
        Receive a personalized shopping list with all your selected items.
      </p>
    </div>
  </div>
</section>

*/}
<div className="flex flex-col items-center justify-center relative w-screen mt-20 pb-24" style={{background: '#eeeeee'}} >
  {/* The line */}
  <div className="absolute top-0 left-0 w-full bg-black" style={{height: '2.4px'}} ></div>
  {/* The SVG positioned so its top aligns with the line */}
  <div className="absolute top-[-5.7px] left-1/2 transform -translate-x-1/2">
    <svg
      width="600px"
      height="80px"
      viewBox="0 0 2129 257"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="Artboard" transform="translate(-559.000000, -1858.000000)">
          <g className="small_d3" transform="translate(559.000000, 1858.000000)">
            <rect
              className="Rectangle"
              fillOpacity="0"
              fill="#FFFFFF"
              x="0"
              y="0"
              width="2129"
              height="257"
            ></rect>
            <path
              d="M1938.5,0 L1938.5,10.43 L1938.25,10.91 L1842.25,10.91 C1795.91,10.91 1714.15,54.42 1622.36,83.43 C1511.43,118.48 1394.25,153.9 1280.58,129.08 C1212.83,114.29 1104.75,80.49 1036.58,67.96 C874.75,38.22 709.76,141.9 548.44,109.49 C443.01,88.32 355.08,10.91 248.25,10.91 L190,10.91 L190,0 L1938.5,0 Z"
              className="filling"
              fill="#f8f6f3"
              fillRule="nonzero"
            ></path>
            <path
              d="M190.25,10.91 L248.25,10.91 C355.08,10.91 443.01,88.32 548.41,109.49 C709.73,141.9 874.72,38.22 1036.55,67.96 C1104.76,80.49 1212.83,114.29 1280.55,129.08 C1394.21,153.9 1511.4,118.48 1622.33,83.43 C1714.12,54.43 1795.89,10.91 1842.22,10.91 L1938.22,10.91"
              className="border"
              stroke="#000000"
              strokeWidth="9"
              strokeLinecap="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </div>

      <section className="p-8 pt-24 pb-12 my-8 rounded-lg text-center w-full relative flex flex-col items-center justify-center">
        <h1 className="text-center text-5xl font-bold text-gray-900 max-w-5xl">
          We looked at <span className="text-purple-400 italic">every store</span> near you, and built multiple presets of <span className="text-purple-400 italic">awesome furniture</span>!
        </h1>
        <p className="text-center text-2xl text-purple-500 italic mt-8 max-w-5xl">
          Choose from over a 100 preset to get you started <span className="text-xl" >(excluding all the for you selected furniture you can add on top afterwards)</span>.
        </p>
      </section>



    <div className="container mx-auto px-4 pb-8">
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 gap-8"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {presetsData["Minimalist Zen"].map((preset, index) => (
          <motion.div 
            key={preset.name} 
            className="flex flex-col rounded-lg shadow-lg overflow-hidden p-2 bg-white"
            variants={itemVariants}
          >
            <div className="relative overflow-hidden">
              <img 
                src={preset.image} 
                alt={preset.name} 
                className="w-full h-64 object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
              />
            </div>
            <div className="pt-4 bg-white">
              <h3 className="text-xl text-center font-semibold text-gray-800">{preset.name}</h3>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>

<p className="text-center text-lg text-gray-500 italic mt-2 max-w-5xl">
          + many many more presets...
        </p>

</div>

<div className="flex flex-col items-center justify-center relative w-screen pb-24" style={{background: '#f8f6f3'}} >
  {/* The line */}
  <div className="absolute top-0 left-0 w-full bg-black" style={{height: '2.4px'}} ></div>
  {/* The SVG positioned so its top aligns with the line */}
  <div className="absolute top-[-5.7px] left-1/2 transform -translate-x-1/2">
    <svg
      width="600px"
      height="80px"
      viewBox="0 0 2129 257"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="Artboard" transform="translate(-559.000000, -1858.000000)">
          <g className="small_d3" transform="translate(559.000000, 1858.000000)">
            <rect
              className="Rectangle"
              fillOpacity="0"
              fill="#FFFFFF"
              x="0"
              y="0"
              width="2129"
              height="257"
            ></rect>
            <path
              d="M1938.5,0 L1938.5,10.43 L1938.25,10.91 L1842.25,10.91 C1795.91,10.91 1714.15,54.42 1622.36,83.43 C1511.43,118.48 1394.25,153.9 1280.58,129.08 C1212.83,114.29 1104.75,80.49 1036.58,67.96 C874.75,38.22 709.76,141.9 548.44,109.49 C443.01,88.32 355.08,10.91 248.25,10.91 L190,10.91 L190,0 L1938.5,0 Z"
              className="filling"
              fill="#eeeeee" 
              fillRule="nonzero"
            ></path>
            <path
              d="M190.25,10.91 L248.25,10.91 C355.08,10.91 443.01,88.32 548.41,109.49 C709.73,141.9 874.72,38.22 1036.55,67.96 C1104.76,80.49 1212.83,114.29 1280.55,129.08 C1394.21,153.9 1511.4,118.48 1622.33,83.43 C1714.12,54.43 1795.89,10.91 1842.22,10.91 L1938.22,10.91"
              className="border"
              stroke="#000000"
              strokeWidth="9"
              strokeLinecap="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </div>

    <section className="p-8 pt-24 pb-12 my-8 rounded-lg text-center w-full relative">
      <h1 className="text-2xl text-purple-400 italic">
        After you've chose the presets
      </h1>
      <p className="text-5xl font-bold text-center text-gray-900 px-8 mt-4">
        Here's a quick look how to make them unique to you.
      </p>

      <div className="flex flex-wrap justify-center gap-2 mt-24 pb-12">
        {/* Task 1 Button */}
        <button className="flex-1 min-w-[150px] max-w-sm cool_button py-2 px-6 rounded-xl">
          Task 1: <br/> Choose a Sofa that fits
        </button>

        {/* Join Now! Button */}
        <button className="flex-1 min-w-[150px] max-w-sm inline-flex items-center justify-center cool_button py-2 px-6 rounded-xl relative">
           Task 2: <br/> Stay under budget
        </button>

        {/* Choose Sofa Button */}
        <button className="flex-1 min-w-[150px] max-w-sm cool_button py-2 px-6 rounded-xl">
           Task 3: <br/> Pick Beautiful Artwork
        </button>
      </div>

       <Demo />

      <p className="text-2xl font-bold text-center text-gray-900 px-8 mt-24">
        For each style, we always provide a list of all the furniture you've chosen for easy ordering:
      </p>

      <div className="overflow-x-auto mt-8 px-12">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Furniture</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Size</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {furnitureItems.map((item, index) => (
              <tr key={index} className="transition duration-300 ease-in-out hover:bg-gray-50">
                <td className="py-4 px-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">{item.name}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-center">
                  <div className="text-sm text-gray-500">{item.size}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-center">
                  <div className="text-sm font-semibold text-gray-900">{item.price}</div>
                </td>
                <td className="py-4 px-4 whitespace-nowrap text-sm font-medium text-center">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cool_button bg-purple-300 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out inline-block"
                  >
                    Link To Order Item
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>

   

</div>


<footer className="flex flex-col items-center justify-center relative w-screen bg-purple-600">
  {/* The line */}
  <div className="absolute top-0 left-0 w-full bg-black" style={{ height: '2.4px' }}></div>
  {/* The SVG positioned so its top aligns with the line */}
  <div className="absolute top-[-5.7px] left-1/2 transform -translate-x-1/2">
    <svg
      width="600px"
      height="80px"
      viewBox="0 0 2129 257"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="Artboard" transform="translate(-559.000000, -1858.000000)">
          <g className="small_d3" transform="translate(559.000000, 1858.000000)">
            <rect
              className="Rectangle"
              fillOpacity="0"
              fill="#FFFFFF"
              x="0"
              y="0"
              width="2129"
              height="257"
            ></rect>
            <path
              d="M1938.5,0 L1938.5,10.43 L1938.25,10.91 L1842.25,10.91 C1795.91,10.91 1714.15,54.42 1622.36,83.43 C1511.43,118.48 1394.25,153.9 1280.58,129.08 C1212.83,114.29 1104.75,80.49 1036.58,67.96 C874.75,38.22 709.76,141.9 548.44,109.49 C443.01,88.32 355.08,10.91 248.25,10.91 L190,10.91 L190,0 L1938.5,0 Z"
              className="filling"
              fill="#f8f6f3"
              fillRule="nonzero"
            ></path>
            <path
              d="M190.25,10.91 L248.25,10.91 C355.08,10.91 443.01,88.32 548.41,109.49 C709.73,141.9 874.72,38.22 1036.55,67.96 C1104.76,80.49 1212.83,114.29 1280.55,129.08 C1394.21,153.9 1511.4,118.48 1622.33,83.43 C1714.12,54.43 1795.89,10.91 1842.22,10.91 L1938.22,10.91"
              className="border"
              stroke="#000000"
              strokeWidth="9"
              strokeLinecap="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </div>

    <section className="flex flex-col items-center justify-center mt-20 max-w-5xl w-full p-8 pt-12 rounded-lg">
      {/* Heading */}
      <div className="w-full text-center pb-8">
        <h2
          className="text-5xl font-bold text-white mb-8"
          style={{
            textShadow: `
              -2px -2px 0 #000,
               2px -2px 0 #000,
              -2px  2px 0 #000,
               2px  2px 0 #000
            `,
          }}
        >
          Pays for itself in furniture cost and time savings!
        </h2>
      </div>

      {/* Pricing Cards Container */}
      <div className="flex flex-wrap justify-center gap-8 w-full">
        {/* First Pricing Card */}
        <div className="max-w-sm">
          <div className="less_cool_button_with_shadow rounded-lg border bg-white text-gray-900 shadow-sm">
            <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="text-3xl font-semibold leading-none tracking-tight">Unlimited Access to all our Tools and Presets</h3>
              <p className="text-sm text-gray-500">Including any update of furniture and future features</p>
            </div>
            <div className="p-6 pt-0 grid gap-4">
              <div className="flex items-baseline justify-center gap-1">
                <span className="text-4xl font-bold">$40</span>
                <span className="text-gray-500">AUD</span>
              </div>
              <ul className="grid gap-2 py-4">
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Access to all current products</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Access to all future products</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Regular updates and new features</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-4 h-4 text-green-500" />
                  <span>Time-saving tools and designs</span>
                </li>
              </ul>
            </div>
            <div className="flex items-center p-6 pt-0">
              <button className="w-full inline-flex items-center justify-center cool_button py-2 px-6 rounded-full relative">
                Join Now!
              </button>
            </div>
          </div>
        </div>

        {/* Second Pricing Card */}
{/*
        <div className="max-w-sm">
          <div className="less_cool_button_with_shadow rounded-lg border bg-purple-100 text-gray-900 shadow-sm">
            <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="text-2xl font-semibold leading-none tracking-tight">Need a custom and personal design?</h3>
              <p className="text-sm text-gray-500">We offer personal consultation and design for various spaces.</p>
            </div>
            <div className="flex items-center p-6 pt-0">
              <button className="w-full inline-flex items-center justify-center cool_button py-2 px-6 rounded-full relative">
                Get a Quote!
              </button>
            </div>
          </div>
        </div>
*/}

        {/* Add more pricing cards as needed */}
      </div>
    </section>

  {/* Footer */}
  <div className="text-white w-full mt-20 py-4 px-6 text-center">
  <div className="bg-black text-white w-full rounded-xl px-6 text-center p-4">
    <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between">
      <div className="mb-6 md:mb-0">
        <h4 className="text-xl font-bold mb-2">RoomDesigner</h4>
        <p className="text-sm">Making every space feel like home.</p>
      </div>
      <div className="flex flex-col space-y-2 mb-6 md:mb-0">
        <a href="#" className="text-sm hover:underline">Privacy Policy</a>
        <a href="#" className="text-sm hover:underline">Terms of Service</a>
        <a href="#" className="text-sm hover:underline">Cookies Policy</a>
      </div>
      <div className="flex flex-col space-y-2">
        <p className="text-sm">© 2024 RoomDesigner. All rights reserved.</p>
      </div>
    </div>
  </div>
  </div>
</footer>






{/*<section className="cool_border bg-white p-8 py-24 my-8 rounded-lg shadow-md max-w-6xl w-full border-2 border-black relative">
<Demo />
</section>*/}

{/* Separator Line with SVG */}






    </div>
  );
};

export default LandingPage;
